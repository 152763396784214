.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  position: relative;
  margin: 10px 0;
  width: 50%;
  margin-left: -40px;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 0;
  margin-right: -40px;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  max-width: 650px;
  text-align: right;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item-content-title {
  color: #333;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
}

.timeline-item-content-subtitle {
  font-size: 24px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 5px;
  max-width: 250px;
  text-align: left;
}

.timeline-item-content-date {
  color: #6c757d;
  font-size: 18px;
  font-weight: normal;
}

.timeline-item-content a {
  /* Descubre más */
  color: #adb5bd;
  text-decoration: none;
  font-size: 20px;
  font-weight: normal;
}

.circle {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 25px);
  right: -65px;
  width: 50px;
  height: 50px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -65px;
}

.timeline-item-content-description {
  font-size: 20px;
  font-weight: normal;
}

@media screen and (max-width: 1023px) {
  .timeline-item-content {
    text-align: left;
  }

  .timeline-item {
    display: flex;
    justify-content: center;
    padding-right: 0px;
    position: relative;
    margin: 10px 0;
    width: 100%;
    z-index: 1;
    margin-bottom: 35px;
  }

  .timeline-item:nth-child(odd) {
    align-self: center;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
  }

  .timeline-item-content {
    align-items: flex-start;
  }

  .timeline-item-content-description {
    font-size: 20px;
    font-weight: normal;
  }

  .timeline-item-content a {
    /* Descubre más */
    color: #adb5bd;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
  }

  .timeline-item-content::after {
    display: none;
  }

  .circle {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: 50%;
  }
}
