:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --background-color: #ecf0f1;
  --text-color: #2c3e50;
  --error-color: #e74c3c;
  --success-color: #2ecc71;
}

.contact-container {
  font-family: "Inter", sans-serif;
  background-color: var(--background-color);
  margin-top: 50px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  height: 100vh;
}

.contact-title {
  display: flex;
  font-size: 5vw;
  margin-bottom: 3vw;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  justify-content: center;
  margin-bottom: 0px;
}
.contact-card {
  position: relative;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  width: 650px;
}

.card-body {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.contact-field label {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  margin-bottom: 10px;
  outline: none;
  font-family: "Inter", sans-serif;
}

.form-control:focus {
  border-color: var(--primary-color);
}

.form-control:focus:invalid {
  border-color: var(--error-color);
}

textarea.form-control {
  min-height: 100px;
  max-height: 150px;
}

input[type="submit"]:disabled,
button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Standard button styling */
.btn {
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Focused state (tab navigation, etc.) */
.btn:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px rgba(52, 152, 219, 0.5); /* var(--primary-color) */
}

/* Hover and active effects for non-disabled buttons */
.btn-primary:not(:disabled):hover,
.btn-primary:not(:disabled):active {
  background-color: #2980b9; /* A darker shade of primary color */
  transform: scale(
    0.98
  ); /* Slightly reduces the button size for a "press" effect */
}

/* Disabled button styling */
.btn:disabled {
  background-color: #bdc3c7; /* Light grey */
  cursor: not-allowed;
  transform: none; /* Ensures no 'press' effect */
}

.btn-block {
  text-align: right;
}

.lottie-overlay {
  position: absolute; /* Positioned within the card */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: semi-transparent background */
}

.lottie-container {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 700px) {
  .contact-card {
    width: 600px;
  }
}

@media screen and (max-width: 650px) {
  .contact-card {
    width: 550px;
  }
}

@media screen and (max-width: 600px) {
  .contact-card {
    width: 400px;
  }
}

@media screen and (max-width: 430px) {
  .contact-card {
    width: 300px;
  }
}
