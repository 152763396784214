.blog-post-detail {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 5vw;
  background-color: #f5f5f5;
  font-family: "Inter", sans-serif;
  margin: 0 auto; /* Center the container on the page */
  width: 90%;
  max-width: 1200px; /* Limit the width of the container */
}

.blog-post-detail .header-container {
  position: relative;
  height: 35vh; /* Adjust this value to get the desired height */
}

.blog-post-detail .header-container h1 {
  position: absolute;
  z-index: 2;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensure it is perfectly centered */
  font-size: 2em; /* Increase font size */
  font-weight: 900;
  color: #fff; /* Adjust color to suit the image background */
  text-align: center; /* Align text to the center */
}

.blog-post-detail .header-container img {
  display: block;
  width: 100%; /* Full width to the container */
  height: 100%; /* Full height to the container */
  object-fit: cover; /* Maintain aspect ratio during resize */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

/* Adding overlay to the image */
.blog-post-detail .header-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the last value to adjust the overlay transparency */
  border-radius: 5px; /* Apply border-radius to overlay */
  pointer-events: none; /* Ensure overlay does not interfere with other elements */
}

.blog-post-detail .content {
  padding-top: 2em;
}

.blog-post-detail h2 {
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 20px;
  color: #555;
}

.blog-post-detail p {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #666;
}

.blog-post-detail p a {
  color: red;
  text-decoration: none;
  transition: color 0.3s;
}

.blog-post-detail p a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .blog-post-detail .header-container {
    position: relative;
    height: 25vh; /* Adjust this value to get the desired height */
    width: 90%;
    margin: 0 auto;
  }

  .blog-post-detail .header-container h1 {
    position: absolute;
    z-index: 2;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    font-weight: 900;
    color: #fff; /* Adjust color to suit the image background */
    text-align: center; /* Align text to the center */
    font-size: 1em;
  }

  .blog-post-detail .header-container img {
    display: block;
    width: 100%; /* Full width to the container */
    height: 100%; /* Full height to the container */
    object-fit: cover; /* Maintain aspect ratio during resize */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  .blog-post-detail p {
    font-size: 12px;
    margin-bottom: 20px;
    color: #666;
  }
}
