.blog-list-container {
  min-height: 100vh;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}

.blog-list-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  padding: 20px 30px;
  grid-gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blog-title {
  display: flex;
  font-size: 5vw;
  margin-bottom: 2vw;
  margin-top: 2vw;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  justify-content: center;
}

.blog-post-excerpt {
  position: relative;
  flex: 0 1 calc(33.333% - 20px);
  height: 300px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  border-radius: 3px;
  overflow: hidden;
}

/* .blog-post-excerpt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
} */

.blog-post-content {
  position: relative;
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  font-family: "Inter", sans-serif;
}

.blog-post-content h2 {
  font-size: 2em;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
}

.read-more-btn {
  display: inline-block;
  background-color: #ff4500;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s;
  text-decoration: none;
  color: white;
  font-weight: 600;

  font-size: 0.8em;
}

.read-more-btn:hover {
  background-color: #ff6347;
  color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
  .blog-title {
    font-size: 45px;
  }

  .blog-post-excerpt {
    flex: 0 1 100%;
    margin-bottom: 15px;
    height: 300px;
  }
  /* .blog-post-excerpt::before {
    height: 100%;
  } */

  .blog-list-container {
    padding: 20px 10px;
  }

  .blog-post-content h2 {
    font-size: 1.2em;
  }

  .read-more-btn {
    padding: 8px 16px;
  }
  .blog-list-list {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    grid-gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .blog-title {
    font-size: 55px;
  }

  .blog-post-excerpt {
    flex: 0 1 calc(50% - 15px);
    height: 350px;
  }

  .blog-list-container {
    padding: 20px 20px;
  }

  .blog-post-content h2 {
    font-size: 1.3em;
  }
}
