.header {
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(33, 37, 41, 0.9);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  display: flex;
  align-items: center;
}

.header__menu {
  display: flex;
  align-items: center;
}

.header__menu-list-item-brand {
  list-style-type: none;
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin: 0;
  display: flex;
  text-decoration: none;
  padding: 12px 16px;
  color: #f5f5f5;
}

.header__menu-list-item-brand:hover {
  color: #ffffff;
  transform: translateY(-2px);

  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, ease-in-out;
}

.header__menu-list {
  list-style-type: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin: 0;
  display: flex;
}

.header__menu-list-item-link {
  text-decoration: none;
  padding: 12px 16px;
  color: #f5f5f5;
}

.header__menu-list-item-link:hover {
  color: #ffffff;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}

.header__logo-image {
  height: 40px;
  width: auto;
}

.header__logo-image:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 1150px) {
  .header {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  .header {
    display: block;
  }
}
