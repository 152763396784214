/* General and Basic Styling */
.about {
  padding: 5vw;
  background-color: #f5f5f5;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-title,
.about-subtitle {
  font-size: 5vw;
  margin-bottom: 3vw;
  font-weight: 900;
  text-align: center;
}

.about-subtitle {
  margin-top: 4vw;
  margin-bottom: 2.5vw;
}

.description-container p {
  padding-top: 1.5vw;
  font-size: 25px;
  margin-bottom: 1vw;
  font-weight: 400;
  text-align: left;
}

/* Lottie Animation Styling */
.lottie-container {
  width: 100%;
  max-width: 400px;
  max-height: 400px;
}

/* Cards Styling */
.card-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  gap: 20px;
}

.about_card {
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.about_card:hover {
  transform: translateY(-10px);
}

.about_card h3 {
  font-size: 25px;
  font-weight: 700;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.about_card p {
  font-size: 20px;
  text-align: left;
}

/* Flexbox Layout Styling */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.column {
  flex: 1;
  padding: 0 10px;
}

@media screen and (max-width: 992px) {
  .about-title {
    font-size: 40px;
  }
  .about-subtitle {
    font-size: 40px;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lottie-container {
    display: none;
  }

  .card-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about_card {
    max-width: 650px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
